/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

 import * as React from "react"
 import PropTypes from "prop-types"
 import { useStaticQuery, graphql } from "gatsby"
 
 function Seo({ description, title, children }) {
   const { site } = useStaticQuery(
     graphql`
       query {
         site {
           siteMetadata {
             title
             description
             author
             siteUrl
           }
         }
       }
     `
   )
 
   const metaDescription = description || site.siteMetadata.description
   const defaultTitle = site.siteMetadata?.title
 
   return (
     <>
       {/* <!-- Primary Meta Tags --> */}
       <title>{defaultTitle ? `${title} | ${defaultTitle}` : title}</title>
       <meta name="title" content={defaultTitle ? `${title} | ${defaultTitle}` : title} />
       <meta name="description" content={metaDescription} />
 
       {/* <!-- Open Graph / Facebook --> */}
       <meta property="og:title" content={defaultTitle} />
       <meta property="og:description" content={metaDescription} />
       <meta property="og:type" content="website" />
       <meta property="og:url" content={site.siteMetadata.siteUrl}></meta>
       <meta property="og:image" content="https://i.imgur.com/y2cmB1J.png" />
 
       {/* <!-- Twitter --> */}
       <meta name="twitter:card" content="summary" />
       <meta name="twitter:creator" content={site.siteMetadata?.author || ``} />
       <meta name="twitter:title" content={defaultTitle} />
       <meta name="twitter:description" content={metaDescription} />
       <meta property="twitter:url" content={site.siteMetadata.siteUrl} />
       <meta property="twitter:card" content="summary_large_image" />
       <meta property="twitter:image" content="https://i.imgur.com/y2cmB1J.png" />
       {children}
     </>
   )
 }
 
 Seo.defaultProps = {
   description: ``,
 }
 
 Seo.propTypes = {
   description: PropTypes.string,
   title: PropTypes.string.isRequired,
 }
 
 export default Seo
 